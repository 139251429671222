<template>
  <div>
    <section class="title-section mt-10">
      <business-image/>
      <h1 class="business-title mt-10">KRYPTEX IT-SOLUTIONS</h1>
      <h2 class="business-subtitle">Brilliante Softwarelösungen für Sie maßgeschneidert</h2>
      <div class="button-container">
        <v-btn @click="scroll()" icon flat class="bg-secondary">
          <v-icon class="text-primary" size="x-large">mdi-arrow-down</v-icon>
        </v-btn>
        <h3 class="description">Mehr erfahren</h3>
      </div>
    </section>
    <section class="vision-section pt-5 mt-10" ref="vision">
      <h1 class="title">Unsere Vision</h1>
      <h2 class="description mt-5 px-10">Unsere Vision als IT-Unternehmen ist es, die Welt zu einem besseren Ort zu machen, indem wir die neuesten Technologien nutzen, um Lösungen für die komplexen Herausforderungen unserer Kunden zu entwickeln. Wir glauben daran, dass Technologie ein mächtiges Werkzeug ist, um das Leben der Menschen zu verbessern und Unternehmen dabei zu helfen, ihre Ziele zu erreichen.</h2>
    </section>
    <!-- <section class="vision-section pt-10">
      <h1 class="title">Unser Ziel</h1>
      <h2 class="description mt-5 px-10">Unser Ziel ist es, unsere Kunden in jeder Phase ihrer IT-Entwicklungsprojekte zu unterstützen, von der Konzeption und Planung bis zur Umsetzung und Wartung. Wir setzen auf die Zusammenarbeit mit talentierten und erfahrenen Freelancern, um maßgeschneiderte Lösungen zu entwickeln, die perfekt auf die Anforderungen und Bedürfnisse unserer Kunden abgestimmt sind.

Wir streben danach, ein führendes IT-Freelancing Unternehmen zu sein, das für seine Qualität und Innovation bekannt ist. Wir investieren kontinuierlich in die Ausbildung unserer Mitarbeiter und die neuesten Technologien, um sicherzustellen, dass wir immer auf dem neuesten Stand sind und unseren Kunden die besten Lösungen bieten können.

Unser Ziel ist es, ein vertrauenswürdiger Partner für unsere Kunden zu sein und gemeinsam mit ihnen zu wachsen. Wir wollen dazu beitragen, dass Unternehmen effizienter arbeiten, ihre Prozesse automatisieren und ihre digitale Präsenz stärken können, um erfolgreich zu sein und ihre Ziele zu erreichen.</h2>
    </section> -->
    <section ref="services" class="business-definition mt-16 bg-background mb-16">
      <h1 class="title">Services</h1>
      <div class="card-container mt-5">
        <div class="card mt-5 mx-5">
          <h1 class="service-text">Softwareentwicklung</h1>
            <!-- <h1 class="service-text" v-show="isHovering">Mehr erfahren</h1> -->
        </div>
        <div class="card text-surface mt-5 mx-5">
          <h1 class="service-text">UI\UX-Design</h1>
            <!-- <h1 class="service-text" v-show="isHovering">Mehr erfahren</h1> -->
        </div>
        <div class="card text-surface mt-5 mx-5">
            <h1 class="service-text">IT-Sicherheitsberatung</h1>
        </div>   
      </div>
    </section>
    <!-- <section class="vision-section mb-10">
      <h1 class="title">Unser Portfolio</h1>
        <v-row class="mt-10">
          <div class="portfolio" style="background-color: #68ebf7; padding: 10px; border-radius: 25px;height: 120px; width: 200px;">
            <v-img height="100" width="200" src="../assets/svg-image-1.png"></v-img>
          </div>
          <div class="ml-5 portfolio" style="background-color: #68ebf7; padding: 10px; border-radius: 25px;height: 120px; width: 200px;">
            <v-img height="100" width="200" src="../assets/Bootsschule1_Logo.png"></v-img>
          </div>
          <div class="ml-5 portfolio" style="background-color: #68ebf7; padding: 10px; border-radius: 25px; display:flex; justify-content: center; align-items: center; height: 120px; width: 200px;">
            <h2 style="color:black; font-weight: 300;">KOLLEKT.CH</h2>
          </div>
        </v-row>
    </section> -->
    <section class="vision-section mb-10">
      <h1 class="title">Kundenbewertungen</h1>
      <v-card class="text-center justify-center align-center bg-secondary mt-10 rating">
        <h2 class="description mt-5">KOLLEKT.CH</h2>
        <h4 class="sub-description">Full Stack Entwicklung mit Vue.js, Nuxt.js und Express.js</h4>
      <v-rating color="warning" readonly v-model="rating"></v-rating>
      <v-btn @click="toggleRatingText" flat icon class="mb-2 show-rating bg-secondary"><v-icon color="primary">{{showRatingText ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon></v-btn>
      <v-card-text v-show="showRatingText">
        "Tim ist ein sympatischer Developer. Die Kommunikation war sehr angenehm und flüssig. Ich schätze seine Flexibilität und hatte den Eindruck, dass er sich sehr gut um verschiedenste Aufgaben kümmern kann. Daher arbeite ich gerne weiterhin mit Tim zusammen."
      </v-card-text>
      </v-card>
    </section>
    <section class="vision-section mb-10">
      <h1 class="title">Ansprechpartner</h1>
      <div class="employees bg-secondary mt-10">
        <div class="ceo">
            <v-avatar size="200">
                <v-img
                    cover
                    src="../assets/Tim.png"
                ></v-img>
            </v-avatar>
            <h2 class="name">Tim Diedloff</h2>
            <p class="emp-title">Fullstack-Webentwickler</p>
            <p class="emp-title">KRYPTEX IT-Solutions</p> 
            <p class="emp-title">tim.diedloff@kryptex-it.de</p>
        </div>
        <div class="ceo-description ml-5 mb-5 mr-5">
           <p class="mt-5 mb-5">"Das beste Mittel, um die richtige Entscheidung zu treffen, ist eine fundierte Beratung. Lassen Sie uns gemeinsam Ihre Ziele und Bedürfnisse besprechen und eine maßgeschneiderte Lösung für Sie finden. Vereinbaren Sie jetzt Ihr unverbindliches Beratungsgespräch und lassen Sie uns den ersten Schritt auf Ihrem Weg zum Erfolg machen."</p>
           <v-btn @click="$router.push('/kontakt')" color="green" class="white--text mt-5">Kostenloses Beratungsgespräch</v-btn>
        </div>
    </div>
    </section>
  </div>
</template>

<script>
import businessImage from "@/components/business-image.vue"
export default {
  name: 'LandingpageView',
  data(){
    return {
      showRatingText: false,
      rating: 5,
      showOverlay: false,
      overlayType: 0,
    }
  },
  components: {
    businessImage
  },
  methods: {
    toggleRatingText(){
      this.showRatingText = !this.showRatingText;
    },
    goTo(route) {
      this.$router.push(route);
    },
    showService(type){
      this.overlayType = type;
      this.showOverlay = true;
    },
    scroll() {
      const el = this.$refs.vision;

      if(el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped lang="scss">


  .portfolio:hover {
    animation: slidein 1s forwards;
  }
  @keyframes slidein {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }
  .rating {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
    border-radius: 25px;

    .show-rating{
      
    }
  }
  
  .vision-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    text-align: center;
  }
  .title-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
  }

  .title {
    font-weight: 200;
    font-size: 30px;
    border-bottom: 2px solid #68ebf7;  
  }

  .description{
    font-weight: 200;
    font-size: 24px;
    width: 50%;
  }
  .sub-description{
    font-weight: 200;
    font-size: 14px;
    width: 70%;
  }
  .business-definition{
    /* border-top: 5px solid #68ebf7; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .business-title{
    text-align: center;
    font-size: 64px;
    font-weight: 100;
  }
  .business-subtitle{
    text-align: center;
    font-size: 30px;
    font-weight: 200;
  }

  @media screen and (max-width: 720px) {
    .business-title {
      font-size: 20pt;
    }
    .business-subtitle {
      font-size: 10pt;
    }

    .description{
      width: 100%;
    }
    .employees {
      flex-direction: column !important;
    }
}
  
  .button-container {
    display: flex;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
  }

  .card-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .card{
    display: flex;
    width: 300px;
    height: 200px;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    border: 1px solid #68ebf7;
    background-color: #121212;
    color: white;

    .service-text {
      font-weight: 200;
      border-bottom: 1px solid #68ebf7;
    }
  }

  .card:hover{
    transform: scale(1.01);
    animation-name: background-animation;
    animation-duration: 1s;
    animation-iteration-count: initial;
    animation-fill-mode: forwards;

    .service-text {
      font-weight: 200;
      // border-bottom: 1px solid #68ebf7;
      animation-name: text-animation;
      animation-duration: 1s;
      animation-iteration-count: initial;
      animation-fill-mode: forwards;
    }
  }

  @keyframes background-animation {
    from {
      background-color: #121212;
    }
    to {
      background-color: #68ebf7;
    }
  }

  @keyframes text-animation {
    from {
      color: white;
    }
    to {
      color: #121212;
      border-bottom: 1px solid #262626;
    }
  }
  .employees{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100%;
        min-width: 370px;
        border-radius: 25px;

        .ceo{
        height: 400px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .name{
        font-weight: 300;
        font-size: 18pt;
    }
    .emp-title{
        font-weight: 100;
        font-size: 12pt;
    }
    .ceo-description{
      max-width: 378px;
    }
    }
</style>
