import { createRouter, createWebHistory } from 'vue-router'
import LandingpageView from '../views/LandingpageView.vue'
import ContactView from '../views/ContactView.vue'
import ImpressumView from '../views/ImpressumView.vue'
import SoftwaredevelopmentView from '../views/SoftwaredevelopmentView.vue'
import AboutUs from '../views/AboutUs.vue'

const routes = [
  {
    path: '/',
    name: 'landingpage',
    component: LandingpageView
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView
  },
  {
    path: '/software-development',
    name: 'software-development',
    component: SoftwaredevelopmentView
  },
  {
    path: '/ueber-uns',
    name: 'ueber-uns',
    component: AboutUs
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
