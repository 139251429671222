<template>
    <div class="employees">
        <div class="ceo">
            <v-avatar size="200">
                <v-img
                    cover
                    src="../assets/Tim.png"
                ></v-img>
            </v-avatar>
            <h1 class="name">Tim Diedloff</h1>
            <h2 class="title">Geschäftsführer KRYPTEX IT-Solutions</h2>
        </div>
    </div>
</template>

<script>
</script>

<style>
    .employees{
        display: flex;
        justify-content: center;
        height: 100%;
    }
    .ceo{
        height: 400px;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .name{
        font-weight: 300;
        font-size: 18pt;
    }
    .title{
        font-weight: 100;
        font-size: 12pt;
    }
</style>